import { TigColor } from "@/components/brand/TigerBuiColor";
const LightModeColor: object = {
    palette: {
        ...TigColor,
        primary: {
            ...TigColor
        }
    }
}

export {
    LightModeColor
}