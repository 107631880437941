import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { PresentationControls, Html, useProgress } from '@react-three/drei'
import Level from './components/Level'
import Sudo from './components/Sudo'
import Camera from './components/Camera'
import Cactus from './components/Cactus'
import Icon from './components/Icon'
import Pyramid from './components/Pyramid'


function Loader() {
  const { progress } = useProgress()
  return <Html center> { progress } % loaded </Html>
}

const ReactThreeFiberJourneyLv1 = () => {
  return (
    <Canvas flat dpr={[1, 2]} camera={{ fov: 25, position: [0, 0, 8] }}>
      <Suspense fallback={<Loader/>}>
        <color attach="background" args={['#FFD7B5']} />
        <ambientLight />
        <PresentationControls snap global zoom={0.8} rotation={[0, -Math.PI / 4, 0]} polar={[0, Math.PI / 4]} azimuth={[-Math.PI / 4, Math.PI / 4]}>
          <group position-y={-0.75} dispose={null}>
            <Level />
            <Sudo />
            <Camera />
            <Cactus />
            <Icon />
            <Pyramid />
          </group>
        </PresentationControls>
      </Suspense>
    </Canvas>
  )
}

export default ReactThreeFiberJourneyLv1;