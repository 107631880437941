const Blogs = () => {
    return (
        <>
            <ul>
                <li>
                    <a href="https://web.dev/learn/pwa/">
                        Learn PWA
                    </a>
                </li>
                <li>
                    <a href="https://www.apollographql.com/blog/graphql/examples/full-stack-react-graphql-tutorial/">
                        Full-stack React + GraphQL Tutorial
                    </a>
                </li>
                <li>
                    <a href="https://css-tricks.com/css-modules-part-3-react/">
                        SS Modules and React
                    </a>
                </li>
                <li>
                    <a href="https://www.digitalocean.com/community/tutorials/build-a-to-do-application-using-django-and-react">
                        How To Build a To-Do application Using Django and React
                    </a>
                </li>
                <li>
                    <a href="https://www.fullstackpython.com/react.html">
                    Full Stack Python & React
                    </a>
                </li>
                <li>
                    <a href="https://www.learningtypescript.com/">
                        The #1 TypeScript book for JavaScript developers out today
                    </a>
                </li>
                <li>
                    <a href="https://basarat.gitbook.io/typescript/">
                    TypeScript Deep Dive
                    </a>
                </li>
            </ul>
        </>
    )
  };
  
  export default Blogs;