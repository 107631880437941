import { Box, Typography } from "@mui/joy";

const NoPage = () => {
    return <>
      <Box component={'div'} sx={{maxWidth: 'sm', marginX: 'auto', minHeight: '50vh'}}>
        <Typography level="h1" sx={{textAlign: 'center', mixBlendMode: 'color-dodge', fontSize: '15rem'}}>
          404
        </Typography>
      </Box>
    </>
  };
  
  export default NoPage;