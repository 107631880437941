import { motion, AnimatePresence } from 'framer-motion'
import { CameraAlt, ArrowBackIos, HighlightAlt, ShoppingCart } from '@mui/icons-material';
import { useSnapshot } from 'valtio'
import { state } from './store'

const TshirtConfigurator = () => {
    const snap = useSnapshot(state)
    const transition = { type: 'spring', duration: 0.8 }
    const config = {
        initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
        animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
        exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
    }
    return (
        <div style={{ position: 'absolute', top: '60px', left: 0, width: '100%', height: '100%' }}>
            <motion.div className='motion-cart' animate={{ x: snap.intro ? 0 : 100, opacity: snap.intro ? 1 : 0, zIndex: snap.intro ? 2 : -1 }} transition={transition}>
                {/* <motion.button type='button' className='btn-cart'>
                    <ShoppingCart style={{fontSize: '2em'}}/>
                </motion.button> */}
            </motion.div>
            <AnimatePresence>
                {snap.intro ? (
                    <motion.section key="main" {...config}>
                        <div className="section--container">
                            <motion.div
                                key="title"
                                initial={{ x: 100, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ type: 'spring', damping: 5, stiffness: 40, restDelta: 0.001, duration: 0.3 }}>
                                <h1>LET'S DO IT.</h1>
                            </motion.div>
                            <div className="support--content">
                                <motion.div
                                    key="p"
                                    initial={{ y: 100, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{
                                        type: 'spring',
                                        damping: 7,
                                        stiffness: 30,
                                        restDelta: 0.001,
                                        duration: 0.6,
                                        delay: 0.2,
                                        delayChildren: 0.2
                                    }}>
                                    <p>
                                        Tự tuỳ chỉnh mẫu áo theo phong cách của bạn với công cụ chỉnh sửa của chúng tôi <br/> <strong>My T-Shirt</strong>
                                    </p>
                                    <button style={{ background: snap.color }} onClick={() => (state.intro = false)}>
                                        Tuỳ chỉnh áo
                                        <HighlightAlt style={{fontSize: '1.2rem'}} />
                                    </button>
                                </motion.div>
                            </div>
                        </div>
                    </motion.section>
                ) : (
                    <motion.section className='cust-group' key="custom" {...config}>
                        <Customizer />
                    </motion.section>
                )}
            </AnimatePresence>
        </div>
    )
}

function Customizer() {
    const snap = useSnapshot(state)

    const clickCanvas = document.querySelector('canvas') as HTMLCanvasElement;

    return (
        <div className="customizer">
            <div className="color-options">
                {snap.colors.map((color) => (
                    <div key={color} className={`circle`} style={{ background: color }} onClick={() => (state.color = color)}></div>
                ))}
            </div>
            <div className="decals">
                <div className="decals--container">
                    {snap.decals.map((decal) => (
                        <div key={decal} className={`decal`} onClick={() => (state.decal = decal)}>
                            <img src={'/t-shirt/' + decal + '_thumb.png'} alt="brand" />
                        </div>
                    ))}
                </div>
            </div>
            <button
                className="share"
                style={{ background: snap.color }}
                onClick={() => {
                    const link = document.createElement('a')
                    link.setAttribute('download', 'canvas.png')
                    link.setAttribute('href', clickCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
                    link.click()
                }}>
                DOWNLOAD
                <CameraAlt style={{fontSize: '1.2rem'}} />
            </button>
            <button className="exit" style={{ background: snap.color }} onClick={() => (state.intro = true)}>
                <ArrowBackIos style={{fontSize: '1.1rem'}} />
                Quay lại
            </button>
        </div>
    )
}

export default TshirtConfigurator;