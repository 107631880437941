import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header';
import SwitchDarkLightModeBg from '@/components/background/switch-dark-light-mode-bg';

class Layout extends Component {
  render(): React.ReactNode {
    
    return (
      <>
        <Header/>
        <Outlet />
        <SwitchDarkLightModeBg/>
      </>
    )
  }
};

export default Layout;