import LightModeBackground from '@/components/background/light-mode-bg';
import DarkModeBackground from '@/components/background/dark-mode-bg';

const SwitchDarkLightModeBg = () => {
    return (
        <>
            <LightModeBackground />
            <DarkModeBackground />
        </>
    )
}

export default SwitchDarkLightModeBg;