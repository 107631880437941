import { TigColor } from "@/components/brand/TigerBuiColor";

const DarkModeColor: object = {
    palette: {
        ...TigColor,
        primary: {
            ...TigColor
        }
    }
};

export {
    DarkModeColor
}