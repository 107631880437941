import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import { useCursor, MeshPortalMaterial, CameraControls, Gltf, Text, RoundedBox } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing, geometry } from 'maath'
import { suspend } from 'suspend-react' // sử dụng font

extend(geometry)

const threepath: string = '/threejs/';

const ReactThreeFiberEnterPortals = () => {
    return (
        <Canvas camera={{ fov: 75, position: [0, 0, 20] }} eventSource={document.getElementById('root') as HTMLElement} eventPrefix="client">
            <color attach="background" args={['#f0f0f0']} />
            <Frame id="01" name={`pick\nles`} author="Omar Faruq Tawsif" bg="#e4cdac" position={[-1.15, 0, 0]} rotation={[0, 0.5, 0]}>
                <Gltf src="/threejs/pickles_3d_version_of_hyuna_lees_illustration-transformed.glb" scale={8} position={[0, -0.7, -2]} />
            </Frame>
            <Frame id="02" name="tea" author="Omar Faruq Tawsif">
                <Gltf src="/threejs/fiesta_tea-transformed.glb" position={[0, -2, -3]} />
            </Frame>
            <Frame id="03" name="still" author="Omar Faruq Tawsif" bg="#d1d1ca" position={[1.15, 0, 0]} rotation={[0, -0.5, 0]}>
                <Gltf src="/threejs/still_life_based_on_heathers_artwork-transformed.glb" scale={2} position={[0, -0.8, -4]} />
            </Frame>
            <Rig />
        </Canvas>
    )
}

function Frame({ id, name, author, bg, width = 1, height = 1.61803398875, children, ...props }: any) {
    const portal = useRef<any>()
    const [, setLocation] = useLocation<any>()
    const [, params] = useRoute(threepath + ':id')
    const [hovered, hover] = useState<boolean>(false)
    useCursor(hovered)
    useFrame((state, dt) => easing.damp(portal.current, 'blend', params?.id === id ? 1 : 0, 0.2, dt))
    return (
        <group {...props}>
            <mesh name={id} onDoubleClick={(e) => (e.stopPropagation(), setLocation(threepath + e.object.name))} onPointerOver={(e) => hover(true)} onPointerOut={() => hover(false)}>
                <RoundedBox args={[width, height, 0.1]}>
                    <Text fontSize={0.3} anchorY="top" anchorX="left" lineHeight={0.8} position={[-0.5, 0.9, 0.01]} material-toneMapped={false}>
                        {name}
                    </Text>
                    <Text fontSize={0.1} anchorX="right" position={[0.4, -0.659, 0.01]} material-toneMapped={false}>
                        /{id}
                    </Text>
                    <Text fontSize={0.04} anchorX="right" position={[0.0, -0.677, 0.01]} material-toneMapped={false}>
                        {author}
                    </Text>
                    <MeshPortalMaterial ref={portal} events={params?.id === id} side={THREE.DoubleSide}>
                        <color attach="background" args={[bg]} />
                        {children}
                    </MeshPortalMaterial>
                </RoundedBox>
            </mesh>
        </group>
    )
}

function Rig({ position = new THREE.Vector3(0, 0, 2), focus = new THREE.Vector3(0, 0, 0) }) {
    const { controls, scene } = useThree<any>()
    const [, params] = useRoute(threepath + ':id')
    useEffect(() => {
        const active = scene.getObjectByName(params?.id) as any
        if (active) {
            active.parent.localToWorld(position.set(0, 0.5, 0.25))
            active.parent.localToWorld(focus.set(0, 0, -2))
        }
        controls?.setLookAt(...position.toArray(), ...focus.toArray(), true)
    })
    return <CameraControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 2} />
}

export default ReactThreeFiberEnterPortals;