
import {
    Container,
    Box
} from '@mui/joy';
import { TigerBuiLogo } from '@/components/brand/tigerbui';
import ToggleColorMode from '@/components/theme-mode/ToggleColorMode';

function ResponsiveAppBar() {

    return (
        <header>
            <nav>
                <Container maxWidth="xl" sx={{display: "flex", p: 2}}>
                    <TigerBuiLogo />
                    <Box component={'div'} sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
                        <ToggleColorMode/>
                    </Box>
                </Container>
            </nav>
        </header>
    );
}
export default ResponsiveAppBar;
