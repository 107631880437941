import React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import { 
  Switch 
} from '@mui/joy';

import {
  DarkMode,
  LightMode
} from '@mui/icons-material';


const ToggleColorMode = () => {
  const { mode, setMode } = useColorScheme();
  return (
    <Switch
      checked={mode === 'dark' ? true : false}
      slotProps={{
        input: { 'aria-label': mode === 'light' ? 'Turn dark' : 'Turn light' },
        thumb: {
          children: mode === 'dark' ? <LightMode/> : <DarkMode />,
        },
      }}
      sx={{
        '--Switch-thumbSize': '1.7rem',
      }}
      onChange={() => {
        setMode(mode === 'light' ? 'dark' : 'light');
      }}
      variant='soft'
    />
  );
}

export default ToggleColorMode;