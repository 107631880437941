import { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, AccumulativeShadows, RandomizedLight, Decal, Environment, Center } from '@react-three/drei'
import { easing } from 'maath'
import { useSnapshot } from 'valtio'
import { state } from './store'
import { styled } from '@mui/joy'
import TshirtConfigurator from '@/components/react-three-fiber/t-shirt-configurator/overlay'

import './t-shirt.styles.scss';

const CanvasWrap = styled('div')`
    height: calc(100vh - 63px)
`

const tshirtPath = '/t-shirt/';

const ReactThreeFiberTshirtConfigurator = ({ position = [0, 0, 2.5], fov = 25 }: any) => {
    return (
        <>
            <CanvasWrap>
                <Canvas shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root') as HTMLElement} eventPrefix="client">
                    <ambientLight intensity={0.5} />
                    <Environment files={tshirtPath + "potsdamer_platz_1k.hdr"} />
                    <CameraRig>
                        <Backdrop />
                        <Center>
                            <Shirt />
                        </Center>
                    </CameraRig>
                </Canvas>
            </CanvasWrap>
            <TshirtConfigurator/>
        </>
    )
}

function Backdrop() {
    const shadows = useRef<any>();
    useFrame((state: any, delta: any) => easing.dampC(shadows.current.getMesh().material.color, state.color, 0.25, delta))
    return (
        <AccumulativeShadows ref={shadows} temporal frames={60} alphaTest={0.85} scale={10} rotation={[Math.PI / 2, 0, 0]} position={[0, 0, -0.14]}>
            <RandomizedLight amount={4} radius={9} intensity={0.55} ambient={0.25} position={[5, 5, -10]} />
            <RandomizedLight amount={4} radius={5} intensity={0.25} ambient={0.55} position={[-5, 5, -9]} />
        </AccumulativeShadows>
    )
}

function CameraRig({ children }: any) {
    const group = useRef<any>();
    const snap = useSnapshot(state)
    useFrame((state, delta) => {
        easing.damp3(state.camera.position, [snap.intro ? -state.viewport.width / 4 : 0, 0, 2], 0.25, delta)
        easing.dampE(group.current.rotation, [state.pointer.y / 10, -state.pointer.x / 5, 0], 0.25, delta)
    })
    return <group ref={group}>{children}</group>
}

function Shirt(props: any) {
    const snap = useSnapshot(state)
    const texture = useTexture(`${tshirtPath}${snap.decal}.png`)
    const { nodes, materials } = useGLTF(`${tshirtPath}shirt_baked_collapsed.glb`) as any
    useFrame((state, delta) => easing.dampC(materials.lambert1.color, snap.color, 0.25, delta))
    return (
        <mesh castShadow geometry={nodes.T_Shirt_male.geometry} material={materials.lambert1} material-roughness={1} {...props} dispose={null}>
            <Decal position={[0, 0.04, 0.15]} rotation={[0, 0, 0]} scale={0.15} map={texture} />
        </mesh>
    )
}

useGLTF.preload(`${tshirtPath}shirt_baked_collapsed.glb`)
    ;[tshirtPath + 'logo512.png', tshirtPath + 't-shirt-demo-three.png', tshirtPath + 't-shirt-demo-three-pmndrs.png'].forEach(useTexture.preload)


export default ReactThreeFiberTshirtConfigurator;