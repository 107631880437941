import { Box, Typography } from "@mui/material";
import WebhookIcon from '@mui/icons-material/Webhook';
import { extendTheme } from "@mui/joy";
import extendThemeColorSchemes from "@/components/brand/mui-joy-styles/color-schemes-extend-theme";
import ComponentsExtendTheme from "@/components/brand/mui-joy-styles/components-extend-theme";

const TigerBuiLogo = () => {
    return (
        <Box component="div" sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
            <WebhookIcon sx={{ mr: 1, fontSize: '2rem' }}/>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                fontSize: '1.5rem'
                }}
            >
                &lt;TigerBui&gt;
            </Typography>
        </Box>
    )
};

const TigerBuiColor = extendTheme({
    colorSchemes: extendThemeColorSchemes,
    components: ComponentsExtendTheme
})

export {
    TigerBuiLogo,
    TigerBuiColor
}