import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.scss';
import Layout from './pages/layouts/LayoutBase';
import Home from './pages/Home';
import Blogs from './pages/blog/Blogs';
import Contact from './pages/Contact';
import NoPage from './pages/NoPage';
import PageWatch from '@/pages/watch';
import ReactThreeFiberTshirtConfigurator from '@/components/react-three-fiber/t-shirt-configurator';
import ReactThreeFiberEnterPortals from '@/components/react-three-fiber/enter-portals';
import ReactThreeShoes from '@/components/react-threejs/shoes';
import ReactThreejsPortalBaked2 from '@/components/react-threejs/portal-baked-2';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path='watch' element={ <PageWatch/> }/>
          <Route path='shop/shoes' element={<ReactThreeShoes/>}/>
          <Route path='tshirt' element={<ReactThreeFiberTshirtConfigurator/>}/>
          <Route path='abstract/enter-portals' element={<ReactThreeFiberEnterPortals/>}/>
          <Route path='abstract/portal-baked-2' element={<ReactThreejsPortalBaked2/>}/>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
