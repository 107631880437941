import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssVarsProvider } from '@mui/joy/styles';
import { TigerBuiColor } from "@/components/brand/tigerbui";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssVarsProvider defaultMode='dark' theme={TigerBuiColor}>
      <App />
    </CssVarsProvider>
  </React.StrictMode>
);

reportWebVitals();
