import ReactThreeFiberJourneyLv1 from '@/components/react-three-fiber/journey-lv1';
import {
  Sheet,
  styled,
} from '@mui/joy';

const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography['body-sm'],
  color: theme.vars.palette.text.secondary,
}));

const ItemIdo = styled(Sheet)(({ theme }) => ({
  backgroundColor: 'rgba(0,0,0,.25)',
  ...theme.typography['body-sm'],
  padding: theme.spacing(1, 2),
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

const FakerDesign = {
  toolsTitle: 'Design',
  idoTitle: 'I can do..',
  tools: [
    {
      name: 'PS',
      title: 'Photoshop',
      skin: 60
    },
    {
      name: 'AI',
      title: 'Illustrator',
      skin: 60
    },
    {
      name: 'FI',
      title: 'Figma',
      skin: 35
    }
  ],
  ido: [
    {
      name: 'Logo / Icon'
    },
    {
      name: 'Web Layout'
    },
    {
      name: 'Social Banner'
    },
    {
      name: 'Landing Page'
    },
    {
      name: 'UI / UX'
    },
    {
      name: '...'
    }
  ]
};

const FakerFEDev = {
  toolsTitle: 'Front-End Dev',
  idoTitle: 'I can do..',
  tools: [
    {
      name: 'HT',
      title: 'HTML',
      skin: 60
    },
    {
      name: 'SC',
      title: 'SCSS/CSS',
      skin: 60
    },
    {
      name: 'JS',
      title: 'Javascript',
      skin: 60
    },
    {
      name: 'TS',
      title: 'Typescript',
      skin: 35
    }
  ],
  ido: [
    {
      name: 'Build tools'
    },
    {
      name: 'Build layout template'
    },
    {
      name: 'Use framework (React, Angular, Vue, Tailwind, Bootstrap, MUI,..)'
    },
    {
      name: 'Using APIs and RESTful Services'
    },
    {
      name: 'Creating Maintaining Mobile and Responsive Design'
    },
    {
      name: 'Developing Across Browsers'
    },
    {
      name: '...'
    }
  ]
};
const FakerBEDev = {
  toolsTitle: 'Back-End Dev',
  idoTitle: 'I can do..',
  tools: [
    {
      name: 'NO',
      title: 'NodeJS',
      skin: 60
    },
    {
      name: 'PY',
      title: 'Python',
      skin: 30
    }
  ],
  ido: [
    {
      name: 'Nodejs: <p>Ad Server</p> <p>Real-time Data Application</p> <p>Cloud Services</p> <p>Websocket server</p> <p>RESTful API</p> <p>Fast File Upload Client</p>'
    },
    {
      name: 'Python - Data visualization, ML'
    }
  ]
}
const dataMock: any = [{ ...FakerDesign }, { ...FakerFEDev }, { ...FakerBEDev }];

const Home = () => {

  return (
    <>
      <ReactThreeFiberJourneyLv1/>
    </>
  )
};

export default Home;