import { proxy } from 'valtio'

const state = proxy({
  intro: true,
  colors: ['#B4B4B4', '#f9ae16', '#42c98b', '#726DE8', '#EF674E', '#424242', '#0a6ccb', '#d80e0e', '#ff7800'],
  decals: ['logo512', 't-shirt-demo-three', 't-shirt-demo-three-pmndrs', 'vite-logo', 'i-ds-outline'],
  color: '#EFBD4E',
  decal: 'logo512'
})

export { state }
