import { DarkModeColor } from "@/components/brand/mui-joy-styles/DarkModeColor";
import { LightModeColor } from "@/components/brand/mui-joy-styles/LightModeColor";
import { TigColorInterface } from "@/components/brand/TigerBuiColor";
// API ://mui.com/joy-ui/customization/themed-components/#extend-colors
// Khai báo sử dụng interface custom 
declare module '@mui/joy/styles' {
    interface Palette extends TigColorInterface {}
}

const extendThemeColorSchemes = {
    light: LightModeColor,
    dark: DarkModeColor
}

export default extendThemeColorSchemes;