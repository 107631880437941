export interface TigColorInterface {
    tigVermilion: string;
    tigMediumBlue: string;
    tigDeepViolet: string;
    tigGreenMunsell: string;
    tigRedRYB: string;
    tigCadmiumPurple: string;
}

const TigColor: TigColorInterface = {
    tigVermilion: '#E03611',
    tigMediumBlue: '#1208BE',
    tigDeepViolet: '#2C0A65',
    tigGreenMunsell: '#09C576',
    tigRedRYB: '#FF3333',
    tigCadmiumPurple: '#BC0F2B'
}

export {
    TigColor
}