
// Docs //mui.com/joy-ui/customization/themed-components/
declare module '@mui/joy/Typography' {
    interface TypographyPropsColorOverrides {
        secondary: true;
        color: true;
    }
}

const ComponentsExtendTheme = {
    JoyTypography: {
        styleOverrides: {
            root: ({ownerState, theme}: any) => ({
                ...(ownerState.color === 'danger' && {
                    color: 'var(--joy-palette-primary-tigRedRYB)'
                })
            })
        }
    }
}

export default ComponentsExtendTheme;